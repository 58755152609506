a { cursor: pointer; }

.app-container {
    min-height: 350px;
}
h1 {
    color: #ffffff;
    margin-left: 200px;
}


table {
    border: 2px solid teal;
}

tr {
    border: 0px;
    border-bottom: 2px solid teal;
}

th,
td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

    .clickable-row:hover {
        background-color: aquamarine;
    }

.selected, .selected:hover {
    background-color: aqua;
}
